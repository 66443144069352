import { Container, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ParcelRobinLogo from 'assets/logos/SVG/PARCELROBIN_RGB_NO-BACKGROUND_LOGO.svg';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const Root = styled('footer')(({ theme, $isMarketing }) => ({
  display: 'flex',
  backgroundColor: '#0D2932',
  color: theme.palette.common.white,
  paddingTop: $isMarketing ? theme.spacing(20) : 0,
  marginTop: $isMarketing ? theme.spacing(-14) : 0,
  // paddingBottom: theme.spacing(6),
}));

const FooterContent = styled(Container)(({ theme, $isMarketing }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.body2.fontWeight,
  paddingTop: theme.spacing(2),
  borderTop: $isMarketing ? '1px solid #1A4452' : 'none',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const LogoImg = styled('img')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  maxWidth: '195px',

  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(2),
  },
}));

// const LinksContainer = styled('div')(() => ({
//   display: 'flex',
//   flexWrap: 'wrap',
//   justifyContent: 'space-between',
//   flexBasis: '50%',
// }));

const LinksColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  // flexDirection: 'column',
  width: '100%',
  paddingBottom: theme.spacing(5),

  [theme.breakpoints.up('md')]: {
    width: 'auto',
    paddingBottom: 0,
  },

  '& a': {
    paddingTop: theme.spacing(2),
    color: theme.palette.common.white,

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1.5),
    },
  },

  '& p': {
    paddingTop: theme.spacing(2),
    color: theme.palette.common.white,

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1.5),
    },
  },
}));

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { pathname } = useLocation();
  const isMarketing = pathname === '/' || pathname === '/business' || pathname === '/individual';

  return (
    <Root>
      <FooterContent $isMarketing={isMarketing} maxWidth="xl">
        <LogoImg alt='ParcelRobin Logo' src={ParcelRobinLogo} />
        {/*<LinksContainer>*/}
        {/*  <LinksColumn>*/}
        {/*    <Link component={RouterLink} to=''>Home</Link>*/}
        {/*    <Link component={RouterLink} to='/auth/login'>Log In</Link>*/}
        {/*    <Link component={RouterLink} to='/auth/signup'>Sign Up</Link>*/}
        {/*    <Link component={RouterLink} to='/individual'>Individuals</Link>*/}
        {/*    <Link component={RouterLink} to='/business'>Businesses</Link>*/}
        {/*  </LinksColumn>*/}
        {/*  <LinksColumn>*/}
        {/*    <Link href='https://facebook.com/parcelrobin' hrefLang='en' rel='noopener noreferrer' target='_blank'>Facebook</Link>*/}
        {/*    <Link href='https://instagram.com/parcelrobinofficial' hrefLang='en' rel='noopener noreferrer' target='_blank'>Instagram</Link>*/}
        {/*    <Link href='https://twitter.com/parcelrobin' hrefLang='en' rel='noopener noreferrer' target='_blank'>Twitter</Link>*/}
        {/*    <Link href='https://linkedin.com/company/parcelrobin' hrefLang='en' rel='noopener noreferrer' target='_blank'>LinkedIn</Link>*/}
        {/*    <Link href='mailto:info@parcelrobin.com' hrefLang='en' rel='noopener noreferrer' target='_blank' title='info@parcelrobin.com'>Email</Link>*/}
        {/*  </LinksColumn>*/}
        <LinksColumn>
          <Link component={RouterLink} sx={{ pr: 2 }} title='Terms' to='terms'>Terms</Link>
          <Link component={RouterLink} sx={{ pr: 2 }} title='Privacy' to='privacy'>Privacy</Link>
          <Typography sx={{ pr: 2 }} variant='body2'>&copy; {currentYear} ParcelRobin LLC</Typography>
        </LinksColumn>
        {/*</LinksContainer>*/}
      </FooterContent>
    </Root>
  );
};

export default Footer;
