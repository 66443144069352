import { Container, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'bottom center',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const Bird = styled('img')(({ theme }) => ({
  height: '2.2rem',

  [theme.breakpoints.up('sm')]: {
    height: '2.7rem',
  },

  [theme.breakpoints.up(930)]: {
    height: '4.3rem',
  },
}));

const Hero = () => (
  <Root>
    <Container maxWidth="md">
      <Paper sx={{ textAlign: 'center', padding: 3 }} variant="outlined">
        <Typography sx={{ mb: 3, fontWeight: '500' }} variant="h4">Closing a Chapter of Savings with ParcelRobin!</Typography>
        <Typography variant="h5">Over the last 3 years, we've helped our users save an impressive <b>$219,913.41</b> on shipping. As we officially close, we want to express our gratitude for your trust in our services. Every parcel, every savings, every memory — it's been a ride to remember. Thank you for being a part of ParcelRobin.</Typography>
      </Paper>
    </Container>
  </Root>
);

export default Hero;
