import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      default: '#f3f4f4',
    },
    primary: {
      main: '#50c0de',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff200',
    },
    tertiary: {
      light: '#79d2ec',
      main: '#42A1BA',
      dark: '#00728a',
      contrastText: '#fff',
    },
    grey: {
      main: '#e0e0e0',
    },
    text: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
  },
  carrierLogos(carrierName = '', logoWidth = 0) {
    switch (carrierName.toLowerCase()) {
      case 'dhl':
        return {
          height: logoWidth/(1000/309),
          width: logoWidth,
        };
      case 'fedex':
        return {
          height: logoWidth/(16/7),
          width: logoWidth,
        };
      case 'ups':
        return {
          height: logoWidth/(14/17),
          width: logoWidth,
        };
      case 'usps':
        return {
          height: logoWidth/(660/110),
          width: logoWidth,
        };
      default:
        return {
          height: logoWidth,
          width: logoWidth,
        };
    }
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          // Some CSS
          // color: '#42A1BA',
        },
      },
    },
  },
});

export default theme;
