import { styled } from '@mui/material/styles';
import Hero from 'Views/Landings/Components/Hero';
import { Helmet } from 'react-helmet-async';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  marginTop: -56,
  [theme.breakpoints.up(600)]: {
    marginTop: -64,
  },
  color: '#0D2932',
  zIndex: 100,
}));

const Index = () => {
  return (
    <Root>
      <Helmet prioritizeSeoTags>
        <title>ParcelRobin | Your Premier Discount Shipping Aggregator</title>
        <meta content="ParcelRobin | Your Premier Discount Shipping Aggregator" property="og:title" />
        <meta content="ParcelRobin | Your Premier Discount Shipping Aggregator" name="twitter:title" />
        <link href="https://parcelrobin.com" rel="canonical" />
        <meta content="https://parcelrobin.com" property="og:url" />
        <meta content="ParcelRobin is a premier discount shipping aggregator for finding discount shipping rates. Sign up today and start saving on shipping costs." name="description"/>
        <meta content="ParcelRobin is a premier discount shipping aggregator for finding discount shipping rates. Sign up today and start saving on shipping costs." property="og:description"/>
        <meta content="ParcelRobin is a premier discount shipping aggregator for finding discount shipping rates. Sign up today and start saving on shipping costs." name="twitter:description"/>
      </Helmet>
      <Hero/>
    </Root>
  );
};

export default Index;
