import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { withProfiler } from '@sentry/react';
import Main from 'Views/Main';
import theme from 'common/theme';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const helmetContext = {};

const App = () => {

  return (
    <HelmetProvider context={helmetContext}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <Router>
            <Main/>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
};

export default withProfiler(App);
