import { styled } from '@mui/material/styles';
import Footer from 'Components/Footer';
import Nav from 'Components/Nav';
import routes from 'common/routes';
import { useRoutes } from 'react-router-dom';

const Root = styled('main')(() => ({
  display: 'flex',
  minHeight: '100vh',
}));

const AppContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Main = () => {
  return (
    <Root>
      <Nav />
      <AppContainer>
        {useRoutes(routes())}
        <Footer />
      </AppContainer>
    </Root>
  );
};

export default Main;
