import Landing from 'Views/Landings';
import Privacy from 'Views/Privacy';
import Terms from 'Views/Terms';

export const routes = () => [
  {
    path: 'terms',
    element: <Terms/>,
    friendlyName: 'Terms and Conditions',
  },
  {
    path: 'privacy',
    element: <Privacy/>,
    friendlyName: 'Privacy Policy',
  },
  {
    path: '*',
    element: <Landing />,
  },
];

export default routes;
