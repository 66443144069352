import { Helmet } from 'react-helmet-async';

const Terms = () => (
  <div style={{ maxWidth: '100vw', padding: '0 16px' }}>
    <Helmet prioritizeSeoTags>
      <title>Terms and Conditions | ParcelRobin</title>
      <meta content="Terms and Conditions | ParcelRobin" property="og:title" />
      <meta content="Terms and Conditions | ParcelRobin" name="twitter:title" />
      <link href="https://parcelrobin.com/terms" rel="canonical" />
      <meta content="https://parcelrobin.com/terms" property="og:url" />
    </Helmet>
    <h1>Terms and Conditions</h1>
    <p>Last updated: August 09, 2021</p>
    <p>Please read these terms and conditions carefully before using Our Service.</p>
    <h2>Interpretation and Definitions</h2>
    <h3>Interpretation</h3>
    <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The
      following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
    <h3>Definitions</h3>
    <p>For the purposes of these Terms and Conditions:</p>
    <ul>
      <li>
        <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a
          party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other
          securities entitled to vote for election of directors or other managing authority.</p>
      </li>
      <li>
        <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our
          Service.</p>
      </li>
      <li>
        <p><strong>Country</strong> refers to: Massachusetts, United States</p>
      </li>
      <li>
        <p><strong>Company</strong> (referred to as either &quot;the
          Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to ParcelRobin LLC,
          1 Washington Mall - 1109, Boston, MA 02108.</p>
      </li>
      <li>
        <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a
          digital tablet.</p>
      </li>
      <li>
        <p><strong>Feedback</strong> means feedback, innovations or suggestions sent by You regarding the attributes,
          performance or features of our Service.</p>
      </li>
      <li>
        <p><strong>Goods</strong> refer to the items offered for sale on the Service.</p>
      </li>
      <li>
        <p><strong>Orders</strong> mean a request by You to purchase Goods from Us.</p>
      </li>
      <li>
        <p><strong>Promotions</strong> refer to contests, sweepstakes or other promotions offered through the Service.
        </p>
      </li>
      <li>
        <p><strong>Service</strong> refers to the Website.</p>
      </li>
      <li>
        <p><strong>Terms and Conditions</strong> (also referred as &quot;Terms&quot;) mean these Terms and Conditions
          that form the entire agreement between You and the Company regarding the use of the Service.</p>
      </li>
      <li>
        <p><strong>Third-party Social Media Service</strong> means any services or content (including data, information,
          products or services) provided by a third-party that may be displayed, included or made available by the
          Service.</p>
      </li>
      <li>
        <p><strong>Website</strong> refers to ParcelRobin, accessible from <a href="https://parcelrobin.com"
                                                                              rel="external nofollow noopener noreferrer"
                                                                              target="_blank">https://parcelrobin.com</a>
        </p>
      </li>
      <li>
        <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal
          entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
      </li>
    </ul>
    <h2>Acknowledgment</h2>
    <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You
      and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of
      the Service.</p>
    <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and
      Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>
    <p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any
      part of these Terms and Conditions then You may not access the Service.</p>
    <p>You represent that you are over the age of 16. The Company does not permit those under 16 to use the Service.</p>
    <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy
      Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and
      disclosure of Your personal information when You use the Application or the Website and tells You about Your
      privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our
      Service.</p>
    <h2>ParcelRobin's Function as an Intermediary</h2>
    <p>ParcelRobin operates exclusively as an intermediary, connecting you with carriers including Dalsey, Hillblom and Lynn
      Express ("DHL Express"), Federal Express ("FedEx"), Lone Star Overnight ("LSO"), United Parcel Service ("UPS"), and
      the United States Postal Service ("USPS"). Through our platform, we facilitate the ordering of postage, shipping
      products, and services from these carriers. ParcelRobin does not provide carrier services directly, nor do we
      transport, ship, deliver, or offer any service other than reselling postage, labels, and the services displayed on our
      Website.</p>
    <p>When we display service options on our Website offered by carriers like DHL Express, FedEx, LSO, UPS, and USPS, it
      does not imply that we recommend, approve, or sponsor that carrier or their service options. We cannot guarantee the
      quality of those services, and your interaction with any carrier accessed through our Website is at your own risk.</p>
    <p>You agree that ParcelRobin has no control over the carriers listed on the Website and is not responsible for the
      accuracy, timeliness, or thoroughness of information related in any way to carriers or their services on the Website.
      You acknowledge that ParcelRobin will have no liability with respect to the acts, omissions, errors, representations,
      warranties, breaches, or negligence of any such carrier or for any personal injuries, death, property damage, or other
      damages or expenses resulting from your interactions with the carrier.</p>
    <p>It is your responsibility to find, open, read, understand, and accept the terms, conditions, or rules of purchase of
      any carrier you select for shipping. You are responsible for ensuring that your purchases abide by the terms and
      conditions of purchase imposed by any carrier you select to perform shipping services, including terms and conditions
      of purchase set forth in a carrier's terms, conditions, and rules. For instance, if a carrier doesn’t permit the
      shipment of hazardous liquids, it is your responsibility to comply with those rules.</p>
    <h2>Placing Orders for Goods</h2>
    <p>By placing an Order for Goods through the Service, You warrant that You are legally capable of entering into
      binding contracts.</p>
    <h3>Your Information</h3>
    <p>If You wish to place an Order for Goods available on the Service, You may be asked to supply certain information
      relevant to Your Order including, without limitation, Your name, Your email, Your phone number, Your credit card
      number, the expiration date of Your credit card, Your billing address, and Your shipping information.</p>
    <p>You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or other payment
      method(s) in connection with any Order; and that (ii) the information You supply to us is true, correct and
      complete.</p>
    <p>By submitting such information, You grant us the right to provide the information to payment processing third
      parties for purposes of facilitating the completion of Your Order.</p>
    <h3>Order Cancellation</h3>
    <p>We reserve the right to refuse or cancel Your Order at any time for certain reasons including but not limited
      to:</p>
    <ul>
      <li>Goods availability</li>
      <li>Errors in the description or prices for Goods</li>
      <li>Errors in Your Order</li>
    </ul>
    <p>We reserve the right to refuse or cancel Your Order if fraud or an unauthorized or illegal transaction is
      suspected.</p>
    <h4>Your Order Cancellation Rights</h4>
    <p>Any Goods you purchase can only be returned in accordance with these Terms and Conditions and Our Returns
      Policy.</p>
    <p>Our Returns Policy forms a part of these Terms and Conditions. Please read our Returns Policy to learn more about
      your right to cancel Your Order.</p>
    <p>Your right to cancel an Order only applies to Goods that are returned in the same condition as You received them.
      You should also include all of the products instructions, documents and wrappings. Goods that are damaged or not
      in the same condition as You received them or which are worn simply beyond opening the original packaging will not
      be refunded. You should therefore take reasonable care of the purchased Goods while they are in Your
      possession.</p>
    <p>We will reimburse You no later than 14 days from the day on which We receive the returned Goods. We will credit
      your account an amount equal to what was paid for the Order, and You will not incur any fees for such
      reimbursement.</p>
    <p>You will not have any right to cancel an Order for the supply of any of the following Goods:</p>
    <ul>
      <li>The supply of Goods made to Your specifications or clearly personalized.</li>
      <li>The supply of Goods which according to their nature are not suitable to be returned, deteriorate rapidly or
        where the date of expiry is over.
      </li>
      <li>The supply of Goods which are not suitable for return due to health protection or hygiene reasons and were
        unsealed after delivery.
      </li>
      <li>The supply of Goods which are, after delivery, according to their nature, inseparably mixed with other
        items.
      </li>
      <li>The supply of digital content which is not supplied on a tangible medium if the performance has begun with
        Your prior express consent and You have acknowledged Your loss of cancellation right.
      </li>
    </ul>
    <h3>Availability, Errors and Inaccuracies</h3>
    <p>We are constantly updating Our offerings of Goods on the Service. The Goods available on Our Service may be
      mispriced, described inaccurately, or unavailable, and We may experience delays in updating information regarding
      our Goods on the Service and in Our advertising on other websites.</p>
    <p>We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images,
      specifications, availability, and services. We reserve the right to change or update information and to correct
      errors, inaccuracies, or omissions at any time without prior notice.</p>
    <h3>Prices Policy</h3>
    <p>The Company reserves the right to revise its prices at any time prior to accepting an Order.</p>
    <p>The prices quoted may be revised by the Company subsequent to accepting an Order in the event of any occurrence
      affecting delivery caused by government action, variation in customs duties, increased shipping charges, higher
      foreign exchange costs and any other matter beyond the control of the Company. In that event, You will have the
      right to cancel Your Order.</p>
    <h3>Additional Charges</h3>
    <p>You are responsible for any additional charges due to:
    <ul>
      <li>Misstatements regarding weight, size, classification number, or manner of Service</li>
      <li>Deliveries on weekends or holidays</li>
      <li>Local customs charges</li>
      <li>Import taxes or duties</li>
      <li>A package being intercepted so it can be delivered to an new address</li>
      <li>A package being returned to the sender for any reason, including being undeliverable due to:</li>
      <ol>
        <li>incorrect or incomplete recipient address</li>
        <li>improper or missing regulatory documents or paperwork</li>
        <li>refusal of delivery by sender or recipient</li>
        <li>unpaid taxes or duties</li>
        <li>containing prohibited and/or restricted items</li>
      </ol>
      <li>Additional handling for non-standard packaging, including any package that:</li>
      <ol>
        <li>is not fully encased in an outer shipping container</li>
        <li>is encased in an outer shipping container not made of corrugated fiberboard (cardboard) materials, including but
          not limited to metal, wood, canvas, leather, hard plastic, soft plastic, or expanded polystyrene foam
        </li>
        <li>is encased in an outer shipping container covered in shrink wrap or stretch wrap</li>
        <li>is encased in a soft-sided pack (e.g., courier packs, poly bags, and bubble mailers)</li>
        <li>is round or cylindrical, including (without limitation) mailing tubes, cans, buckets, barrels, tires, drums, or
          pails
        </li>
        <li>is bound with metal, plastic, or cloth banding, or has wheels, casters, handles, or straps or packages where the
          outer surface area is loosely wrapped, or where the contents protrude outside the surface area
        </li>
        <li>could become entangled in or cause damage to other packages or the carrier sortation system</li>
      </ol>
      <li>any similar charge(s) incurred</li>
    </ul>
    If any such charges become due as a result of Services performed by a carrier on your behalf and are charged to Us by a carrier, you agree that We may charge any
    additional amounts due on the credit card or bank account used when such Services were purchased or, in the event
    that you paid for the Services in another manner, you agree to reimburse US fully for such charges within
    seven (7) days of our demand. We are not required to automatically provide any refunds or reduction of
    charges for any misstatement by you of weight, size, number or manner of Service.
    </p>
    <h3>Payments</h3>
    <p>All Goods purchased are subject to a one-time payment. Payment can be made through various payment methods we
      have available, such as Visa, MasterCard, American Express cards.</p>
    <p>Payment cards (credit cards or debit cards) are subject to validation checks and authorization by Your card
      issuer. If we do not receive the required authorization, We will not be liable for any delay or non-delivery of
      Your Order.</p>
    <h2>Insurance Coverage</h2>
    <p>ParcelRobin Shipping Insurance does not include:</p>
    <ol>
      <li>Coins, bullion, loose diamonds or stones, jewelry, rock slabs, stocks, bonds, currency, deeds, evidence of debt,
        travelers checks, money orders, gift certificates, calling cards, lottery tickets, event tickets, or any other
        negotiable documents.
      </li>
      <li>Loss from delay, deterioration, spoilage or contamination of perishable merchandise except when resulting from
        fire.
      </li>
      <li>Merchandise shipped on consignment, memorandum or approval unless shipped in fulfillment of an order or request.
      </li>
      <li>Loss, damage, or non-arrival of any package or its contents which (a) is addressed, wrapped, or packed
        insufficiently, incorrectly, or contrary to carrier's packaging requirements; or (b) bears a descriptive label or
        packaging which tends to describe nature of contents EXCEPT if shipped via Parcel Post and required by Postal Laws
        and Regulations.
      </li>
      <li>Damage which is deemed exceedingly minor, frequently occurring, or caused by the nature of the product itself
        regardless of careful handling.
      </li>
      <li>Cosmetic damages limited to the packaging where the intended good is not damaged.</li>
      <li>Any package where the damage is deemed preventable had the shipment included the appropriate use of packing
        materials such as, but not limited to, packaging tape, bubble wrap, packing peanuts, crumpled paper, styrofoam, or
        mail rollers.
      </li>
      <li>Any package containing personal goods, gifts, samples, or promotional items shipped on behalf of the insured or
        insured's employees.
      </li>
      <li>Any package shipped to (a) a prohibited country or any location that would be in violation of any U.S. economic or
        trade sanctions, including, without limitation, Office of Foreign Assets Control (OFAC) Restricted or (b) any
        country or any location that would be in violation of applicable U.S. and/or carrier shipping restrictions related
        to COVID-19 or other disease-related outbreak, as may be updated from time to time. Information can be found <a
          href="https://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx"
          rel="external nofollow noopener noreferrer" target="_blank">here</a>.
      </li>
      <li>Against loss or damage arising out of dishonesty on the part of the insured or insured's employees.</li>
      <li>Against loss or damage caused by or resulting from: (a) hostile or warlike action in time of peace or war,
        including action in hindering, combating or defending against an actual, impending or expected attack, (1) by any
        government or sovereign poser (de jure facto), or by any authority maintaining or using military, naval or air
        forces; or (2) by military, naval or air forces; (3) by an agent of any such government power, authority or forces
        (b) any weapon of war employing atomic fission or radioactive force whether in time of peace or war; (c)
        insurrection, rebellion, revolution, civil war, usurped power, or action taken by governmental authority in
        hindering, combating or defending against such an occurrence, seizure, or destruction under quarantine or Customs
        regulations, confiscation by order of any government or public authority or risks of contraband or illegal
        transportation of trade.
      </li>
      <li>Against loss or damage caused by nuclear reaction/radiation or radioactive contamination, all whether controlled
        or uncontrolled, and whether such loss be direct or indirect, proximate or remote, or be in whole or in part caused
        by, contributed to, or aggravated by the peril(s) insured against in this Policy; however, direct loss by "fire"
        resulting from nuclear reaction/radiation or radioactive contamination is insured against by this Policy.
      </li>
      <li>Against loss or damage caused by Customs seizure, inspection, handling, or destruction.</li>
      <li>Any package containing goods prohibited or restricted from entering the intended country of delivery.</li>
      <li>Surcharges, including but not limited to: shipping costs, tariffs, seller fees, and processing fees.</li>
    </ol>
    <h3>Insurance Conditions</h3>
    <ul>
      <li>ParcelRobin will not be liable if packages are not reported to ParcelRobin within 30 days and where the related
        premiums had not remitted. When submitting a claim to ParcelRobin, you waive the right to seek a claim with any
        other insurance provider for the same shipment. ParcelRobin will not cover any package that is already being
        processed by other carrier insurance(s). ParcelRobin will not process any claims which are initiated, pending, or
        completed by, another insurance provider.
      </li>
      <li>All claims regarding damage are to be completed with ParcelRobin within thirty (30) days of shipping label
        creation. All claims regarding loss are to be completed with ParcelRobin thirty (30) days past shipping label
        creation, but no later than sixty (60) days past shipping label creation. You will submit (a) a completed
        ParcelRobin claim form, (b) a copy of original invoice to customer, (c) a copy of customer note stating damage
        sufficient to substantiate loss or stating package had failed to be delivered, (d) and photos displaying the damage
        to the product and packaging (if applicable). The package recipient should hold damaged items in the event they are
        requested during claim processing. All damaged property for which payment (not repair cost) or replacement has been
        requested or made, must on request, be returned to ParcelRobin. FAILURE TO RETAIN DAMAGE PROPERTY MAY AFFECT FINAL
        SETTLEMENT OF CLAIM.
      </li>
      <li>Claims regarding loss where the carrier's tracking records have the shipment marked as delivered will not be
        honored, unless documentation from the carrier is provided, which acknowledges a mistake in the tracking
        information, or if adequate proof is provided to support theft or mishandling.
      </li>
      <li>Claims regarding loss or damage where the shipment is signed for will not be honored, unless adequate proof is
        provided to support theft or mishandling.
      </li>
      <li>For claims regarding damage, ParcelRobin may request for the item to be examined and a repair quote to be issued
        and submitted to ParcelRobin for review. If the repair service is able to restore the item to its intended
        condition, ParcelRobin may choose to settle the claim for the value of the repair, rather than the full value of the
        item.
      </li>
      <li>For claims regarding theft, stolen property or misdelivery, ParcelRobin requires (a) signature-required delivery
        for those shipments valued at five hundred dollars ($500) or greater and (b) valid tangible documentation and/or
        evidence for any claims exceeding five hundred dollars ($500). Such valid documentation and/or evidence may include,
        without limitation: (i) written acknowledgement of such theft, stolen property or misdelivery from the respective
        carrier; (ii) video footage, (iii) police report and/or (iv) other documentation and evidence. ParcelRobin will not
        accept any claims for theft, stolen property or misdelivery where there is a valid signature from customer, or a
        customer representative, acknowledging receipt of a shipment.
      </li>
      <li>ParcelRobin may request additional documentation or information during the claim process. Additional documentation
        or information may be requested from the recipient, carrier, manufacturer, 3PL warehouse, or any other 3rd party
        along the supply chain.
      </li>
      <li>The maximum amount of insurance that can be purchased through ParcelRobin is $5,000 per shipment.</li>
      <li>Any covered loss will be credited by ParcelRobin directly to the insured's ParcelRobin account within thirty (30)
        days of submitting its claim; provided that ParcelRobin receives (i) the claim and (ii) all required claim
        documentation and requested damaged goods where applicable.
      </li>
      <li>No suit or action for the recovery of any claim under this Policy shall be sustainable in any court of law or
        equity unless commenced within twelve (12) months after you discover the occurrence which gives rise to the claim,
        provided, however, that if by the laws of the State within which this Policy is issued, such limitation is invalid,
        then any such claim shall be void unless such action or suit commences with the shortest limit of time permitted by
        the laws of that State.
      </li>
      <li>This policy may be canceled at any time by your written request or by us upon thirty (30) days written notice, or
        the number of days if greater, required by the regulations in your State. However, such cancellation shall not
        affect any shipments already made for which reports and premium have been tendered by you and accepted by
        ParcelRobin.
      </li>
      <li>Policy changes can only be affected by ParcelRobin.</li>
      <li>This Policy treats banded boxes and pallets of boxes as one single package.</li>
      <li>If a part of a pair or set is lost or damaged, ParcelRobin will only pay a reasonable and fair portion of the
        total value of the pair or set and NOT the total value.
      </li>
      <li>This Policy is void if you have intentionally, fraudulently, or recklessly concealed or misrepresented any
        material fact or circumstance relating to this insurance.
      </li>
      <li>ParcelRobin may update, modify, alter or amend this Policy from time to time.</li>
    </ul>
    <h2>Promotions</h2>
    <p>Any Promotions made available through the Service may be governed by rules that are separate from these
      Terms.</p>
    <p>If You participate in any Promotions, please review the applicable rules as well as our Privacy policy. If the
      rules for a Promotion conflict with these Terms, the Promotion rules will apply.</p>
    <h2>User Accounts</h2>
    <p>When You create an account with Us, You must provide Us information that is accurate, complete, and current at
      all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your
      account on Our Service.</p>
    <p>You are responsible for safeguarding the password that You use to access the Service and for any activities or
      actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.</p>
    <p>You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of
      any breach of security or unauthorized use of Your account.</p>
    <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a
      name or trademark that is subject to any rights of another person or entity other than You without appropriate
      authorization, or a name that is otherwise offensive, vulgar or obscene.</p>
    <h2>Intellectual Property</h2>
    <p>The Service and its original content (excluding Content provided by You or other users), features and
      functionality are and will remain the exclusive property of the Company and its licensors.</p>
    <p>The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.</p>
    <p>Our trademarks and trade dress may not be used in connection with any product or service without the prior
      written consent of the Company.</p>
    <h2>Your Feedback to Us</h2>
    <p>You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such
      assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free,
      worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback
      without restriction.</p>
    <h2>Links to Other Websites</h2>
    <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the
      Company.</p>
    <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices
      of any third party web sites or services. You further acknowledge and agree that the Company shall not be
      responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in
      connection with the use of or reliance on any such content, goods or services available on or through any such web
      sites or services.</p>
    <p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or
      services that You visit.</p>
    <h2>Termination</h2>
    <p>We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason
      whatsoever, including without limitation if You breach these Terms and Conditions.</p>
    <p>Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account,
      You may simply discontinue using the Service.</p>
    <h2>Limitation of Liability</h2>
    <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers
      under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the
      amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the
      Service.</p>
    <p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for
      any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for
      loss of profits, loss of data or other information, for business interruption, for personal injury, loss of
      privacy arising out of or in any way related to the use of or inability to use the Service, third-party software
      and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms),
      even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy
      fails of its essential purpose.</p>
    <p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or
      consequential damages, which means that some of the above limitations may not apply. In these states, each party's
      liability will be limited to the greatest extent permitted by law.</p>
    <h2>&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</h2>
    <p>The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and defects
      without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf
      and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims
      all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all
      implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and
      warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without
      limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any
      kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any
      other software, applications, systems or services, operate without interruption, meet any performance or
      reliability standards or be error free or that any errors or defects can or will be corrected.</p>
    <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or
      warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the
      information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or
      error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the
      Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are
      free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
    <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable
      statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But
      in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent
      enforceable under applicable law.</p>
    <h2>Governing Law</h2>
    <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the
      Service. Your use of the Application may also be subject to other local, state, national, or international
      laws.</p>
    <h2>Disputes Resolution</h2>
    <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by
      contacting the Company.</p>
    <h2>For European Union (EU) Users</h2>
    <p>If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in
      which you are resident in.</p>
    <h2>United States Legal Compliance</h2>
    <p>You represent and warrant that (i) You are not located in a country that is subject to the United States
      government embargo, or that has been designated by the United States government as a &quot;terrorist
      supporting&quot; country, and (ii) You are not listed on any United States government list of prohibited or
      restricted parties.</p>
    <h2>Severability and Waiver</h2>
    <h3>Severability</h3>
    <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and
      interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law
      and the remaining provisions will continue in full force and effect.</p>
    <h3>Waiver</h3>
    <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this
      Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter
      nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</p>
    <h2>Translation Interpretation</h2>
    <p>These Terms and Conditions may have been translated if We have made them available to You on our Service.
      You agree that the original English text shall prevail in the case of a dispute.</p>
    <h2>Changes to These Terms and Conditions</h2>
    <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is
      material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect.
      What constitutes a material change will be determined at Our sole discretion.</p>
    <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the
      revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the
      Service.</p>
    <h2>Contact Us</h2>
    <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
    <ul>
      <li>By email: info@parcelrobin.com</li>
    </ul>
  </div>
);

export default Terms;
