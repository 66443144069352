import {
  AppBar,
  Link,
  Toolbar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ParcelRobinLogo from 'assets/logos/SVG/PARCELROBIN_RGB_NO-BACKGROUND_LOGO.svg';
import { NavLink } from 'react-router-dom';

export const drawerWidth = 240;
export const miniDrawerWidth = 72;
export const desktopNavBreakpoint = 850;

const NavBar = styled(AppBar)(({ theme }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  '&.appBarShift': {
    marginLeft: drawerWidth,
    width: `calc(100% - (${drawerWidth}px))`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const NavLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
}));

const LogoWrapper = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '& > img': {
    height: theme.spacing(3),
    [theme.breakpoints.up(450)]: {
      height: theme.spacing(3.5),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(4),
    },
  },
}));

export const MenuLink = styled(Link)(({ theme }) => {
  return {
    cursor: 'pointer',
    fontWeight: 500,
    paddingRight: theme.spacing(2),

    '&.active': {
      textDecoration: 'underline',
    },

    '&:last-of-type': {
      paddingRight: 0,
    },
  };
});

const Nav = ({ drawerOpen }) => {
  const navBackgroundColor = 'primary';
  const navElevation = 4;
  const navLogo = ParcelRobinLogo;

  return (
    <NavBar
      color={navBackgroundColor}
      elevation={navElevation}
    >
      <Toolbar>
        <NavLeft>
          <LogoWrapper component={NavLink} to="/">
            <img
              alt="ParcelRobin logo"
              src={navLogo}
            />
          </LogoWrapper>
        </NavLeft>
      </Toolbar>
    </NavBar>
  );
};

export default Nav;
